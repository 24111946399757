const React = require("react");
const Layout = require("@eimerreis/lauterbad-components").Layout;

const socialMediaLinks = {
    Instagram: "https://www.instagram.com/berghuettelauterbad",
    Facebook: "https://www.facebook.com/berghuettelauterbad",
    Mail: "mailto:info@fritz-lauterbad.de",
    Phone: "tel:+4974419509966"
}

const logo = "https://a.storyblok.com/f/179134/1890x1890/3083ce10af/logo-berghutte-lauterbad-hirsch_3d_grau-weiss.png";

exports.wrapPageElement = ({ element, props }) => {
    return (
        <Layout {...{...props.pageContext, socialMediaLinks, logo}}>
            {element}
        </Layout>
    )
}
